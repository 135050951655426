<template>
  <div>
    <b-row>
      <b-col md="3" class="">
        <iq-card class="col-sm-12 p-0">
          <template v-slot:body>
            <v-list>
              <div v-for="item in items" :key="item.name" >
                <v-list-item v-if="item.tasks.length === 0" :class="`${selectedMenu === item.token ? 'is_active' : ''}`" class="menu-text"  @click="setCategory(item.token)">
                  <v-list-item-title >{{ item.name }}</v-list-item-title>
                </v-list-item>
                <v-list-group v-if="item.tasks.length > 0" no-action>
                  <template v-slot:activator>
                    <v-list-item-content :class="`${selectedMenu === item.token ? 'is_active' : ''}`" class="menu-text">
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item v-for="child in item.tasks" :key="child.name" >
                    <v-list-item-content class="menu-subtext">
                      <v-list-item-title v-text="child.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </div>
            </v-list>
          </template>
        </iq-card>
      </b-col>
      <b-col md="12" lg="9">
        <b-row class="p-0 m-0">
          <iq-card class="col-sm-12">
            <template v-slot:body>
              <div class="text-right">
                <b-button type="submit" variant="primary">
                  <i class="ri-filter-3-line"></i> Filter
                </b-button>
                <b-button type="submit" variant="primary">
                  <i class="ri-search-line"></i> Search
                </b-button>
              </div>
            </template>
          </iq-card>
          <div class="col-sm-4 p-1" v-for="(item, key) in rowData" :key="key">
            <iq-card class="p-0">
              <template v-slot:body>
                <div>
                  <router-link tag="a" :to="{ name: 'customer.product', params: { product: item.token }}">
                    <div class="h-40 d-flex align-items-center justify-content-center bg-white iq-border-radius-15">
                      <img :src="item.img || require('../../assets/images/product-placeholder.png')"
                          :alt="item.name"
                          class="grid-view-img px-4 pt-4 w-100"
                          style="width: 100%"
                      >
                    </div>
                  </router-link>
                  <div class="card-body"><!----><!---->
                    <div class="text-justify">
                      <router-link tag="a" :to="{ name: 'customer.product', params: { product: item.token }}">
                        {{ item.name }} <span>...</span>
                      </router-link>
                    </div>
                    <div class="iq-product-action my-2">
                      <p class="font-size-16 font-weight-bold float-left">{{ item.price }} {{ item.currency }}</p></div>

                      <button type="button" class="btn btn-primary ml-2 btn-sm float-right" style="font-size:9px" @click="addToCart(item)">
                        <i class="fas fa-cart-plus mr-0"></i> Add to card
                      </button>

                    <button type="button" style="font-size:9px" class="btn btn-success iq-waves-effect text-uppercase btn-sm float-right" @click="addToWishlist(item)">
                      <i class="fas fa-heart mr-0"></i>
                    </button>

                  </div>
                </div><!---->
              </template>
            </iq-card>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

export default {
  name: 'ProductListing',
  props: {
    thecompanytoken: String,
    theuser: Object
  },
  data () {
    return {
      rowData: [],
      items: {},
      selectedMenu: ''
    }
  },
  mounted () {
    this.getCategories()
    this.getProducts()
  },
  methods: {
    addToCart (item, quantity = null) {
      let products = []
      if (localStorage.getItem('products')) {
        products = JSON.parse(localStorage.getItem('products'))
      }
      let exists = false
      let theQuantity = 0
      for (let x = 0; x < products.length; x++) {
        if (products[x].productId === item.token) {
          exists = true
          if (quantity) {
            products[x].quantity = products[x].quantity + quantity
          } else {
            products[x].quantity = products[x].quantity + 1
          }

          theQuantity = products[x].quantity
        }
      }
      if (!exists) {
        if (quantity) {
          theQuantity = quantity
        } else {
          theQuantity = 1
        }
        products.push({ productId: item.token, quantity: theQuantity, productName: item.name, productImage: item.img, productPrice: item.price, productCurrency: item.currency })
      }

      localStorage.setItem('products', JSON.stringify(products))
      this.$emit('update_products')
      Swal.fire({
        title: item.name,
        text: 'is added to cart!',
        icon: 'success',
        confirmButtonText: 'OK'
      })
    },
    addToWishlist (item, quantity) {
      let products = []
      if (localStorage.getItem('wishlist')) {
        products = JSON.parse(localStorage.getItem('wishlist'))
      }
      let exists = false
      for (let x = 0; x < products.length; x++) {
        if (products[x].productId === item.token) {
          exists = true
          products[x].quantity = products[x].quantity + quantity
        }
      }
      if (!exists) {
        products.push({ productId: item.token, quantity: quantity })
      }
      localStorage.setItem('wishlist', JSON.stringify(products))
      this.$emit('update_wishlist')
    },
    setCategory (token) {
      if (token === this.selectedMenu) {
        this.selectedMenu = ''
      } else {
        this.selectedMenu = token
      }
      this.filterProducts(this.selectedMenu)
    },
    filterProducts (category = null, name = null, price = null) {
      const dataPost = {
        company: this.thecompanytoken
      }
      if (category) {
        dataPost.category = category
      }
      if (name) {
        dataPost.search = name
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_FILTER_PRODUCTS, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rowData = response.data.product
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getCategories () {
      const dataPost = {
        company: this.thecompanytoken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_CATEGORIES, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.items = response.data.categories
            this.someItems = response.data.categoryList
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getProducts () {
      const dataPost = {
        company: this.thecompanytoken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_PRODUCTS, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rowData = response.data.product
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  }
}
</script>

<style scoped>
  .menu-text:hover,
  .submenu-text:hover{
    background: #0c88b4;
    color: #fff !important;
    cursor: pointer;
  }

  .is_active{
    background: #0c88b4;
    color: #fff !important;
    cursor: pointer;
  }
</style>

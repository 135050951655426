<template>
  <b-row>
    <b-col lg="12">
      <iq-card body-class="p-0" style="margin-bottom: 10px;">
        <template v-slot:body>
          <div class="iq-edit-list">
            <tab-nav :pills="true" class="iq-edit-profile d-flex">
              <tab-nav-items class="col-md-3 p-0" ref="theServices" :active="true" href="#services" :title="$t('booking.services')"/>
              <tab-nav-items v-if="selectedEmployeeByDefault.length === 0" class="col-md-3 p-0" :class="selectedServiceName === '' ? 'disabled' : ''" ref="theEmployees" :active="false" href="#employees" :title="$t('booking.employees')"/>
              <tab-nav-items class="col-md-3 p-0" :class="selectedEmployeeName === '' ? 'disabled' : ''" ref="theProgram" :active="false" href="#program" :title="$t('booking.program')"/>
              <tab-nav-items class="col-md-3 p-0" :class="selectedDateName === '' ? 'disabled' : ''" ref="theDetails" :active="false" href="#detailsAndPayment" :title="$t('booking.details')"/>
            </tab-nav>
          </div>
        </template>
      </iq-card>
    </b-col>
    <b-col lg="12">
      <nav aria-label="breadcrumb" v-if="this.selectedServiceName !== ''">
        <ol class="breadcrumb bg-primary mb-0">
          <li><a href="#" class="text-white">{{ selectedServiceName }} </a></li>
          <li v-if="this.selectedEmployeeName" class="ml-1"><a href="#" class="text-white">- {{ selectedEmployeeName }}</a></li>
          <li v-if="this.selectedDateName" class="ml-1">- {{ selectedDateName }}</li>
        </ol>
      </nav>
    </b-col>
    <b-col lg="12">
      <div class="isLoadingEvent" v-if="isLoadingEvent"><div class="loader"></div></div>
      <div class="iq-edit-list-data">
        <tab-content id="pills-tabContent-2">
          <tab-content-item :active="true" id="services" >
            <iq-card>
              <template v-slot:body>
                  <div class="row topStickyHeader">
                    <div class="col-sm-8">
                      <input type="text" class="form-control round" id="service-search" :placeholder="$t('booking.search_service')" v-model="search" @input="filteredList">
                    </div>
                    <div class="col-sm-4 text-right">
                      <b-button variant="primary" class="mr-2" @click="selectEmployee()">{{ $t('booking.select_employee') }}</b-button>
                    </div>
                  </div>

                  <div class="services-results">
                    <div v-for="(val, id) in services" :key="id" style="margin-top:10px;font-size: 12px; line-height: 15px;">
                      <h5 style="padding:5px;background: #9c7af3;color: white;margin: 10px 0px; position: relative; cursor: pointer" @click="toggleCard(id)">
                        {{val.title}} ({{ val.list.length }} - {{ val.list.length === 1 ?  $t('calendar.service') : $t('calendar.services')}})
                        <div class="extendible">
                          <i class="fas fa-plus-square" :id="'plus' + id"></i>
                          <i class="far fa-minus-square" :id="'minus' + id"></i>
                        </div>
                      </h5>
                      <b-row :id="'serv_' + id" class="suppress">
                        <b-form-group class="col-sm-6" v-for="(service, serviceId) in val.list" :key="serviceId" v-model="service.id" :ref="'service_'+service.id" style="cursor: pointer;">
                          <b-card no-body style="border-bottom: 1px solid #dfdfdf; margin-top: 5px" @click="setService(service)">
                            <b-row no-gutters>
                              <b-col sm="3" class="image_of_service col">
                                <b-card-img :src="service.profile_image || require('../../../assets/images/page-img/09.jpg')"></b-card-img>
                              </b-col>
                              <b-col sm="9" class="pl-1 col">
                                <h4>{{ service.name }}</h4>
                                {{ $t('booking.time') }}: {{ service.duration }} {{ service.duration_type }} <br>
                                {{ $t('booking.price') }}: {{ service.price }} {{ currency }} <br>
                                {{ service.description }}
                              </b-col>
                            </b-row>
                          </b-card>
                        </b-form-group>
                      </b-row>
                    </div>
                  </div>
              </template>
            </iq-card>
          </tab-content-item>
          <tab-content-item :active="false" id="employees" >
            <iq-card>
              <template v-slot:body>
                <div class="form-group chat-search-data mb-4">
                  <input type="text" class="form-control round" id="employee-search" :placeholder="$t('booking.search_employee')" v-model="searche" @input="filteredListEmployee">
                </div>

                <b-row>
                  <b-form-group class="col-sm-6">
                    <b-card no-body style="border-bottom: 1px solid #dfdfdf;">
                      <b-row no-gutters>
                        <b-col md="3" class="image_of_employee">
                          <i class="fas fa-user-secret" style="font-size: 120px;color: #467c8e;"></i>
                        </b-col>
                        <b-col md="8">
                          <b-card-body class="b-card-details">
                            <h4>{{ $t('booking.next_employee') }}</h4>
                            {{ $t('booking.next_employee_detail') }} <br><br>
                            <b-button variant="primary" class="mr-2" @click="setEmployeeWithAvailability">{{ $t('booking.select_employee') }}</b-button>
                          </b-card-body>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-form-group>
                  <b-form-group class="col-sm-6" v-for="(emp, id) in employees" :key="id" v-model="emp.id" :ref="'employee_'+emp.id">
                    <b-card no-body style="border-bottom: 1px solid #dfdfdf;">
                      <b-row no-gutters>
                        <b-col md="3" class="image_of_employee">
                          <b-card-img :src="emp.logo || require('../../../assets/images/page-img/16.jpg')"></b-card-img>
                        </b-col>
                        <b-col md="8">
                          <b-card-body class="b-card-details">
                            <h4>{{ emp.name }}</h4>
                            {{ emp.description }} <br><br>
                            <b-button variant="primary" class="mr-2" @click="setEmployee(emp)">{{ $t('booking.select_employee') }}</b-button>
                          </b-card-body>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-form-group>
                </b-row>
              </template>
            </iq-card>
          </tab-content-item>
          <tab-content-item :active="false" id="program" >
            <b-row>
              <b-form-group class="col-sm-12 m-0">
                <iq-card class="m-2">
                  <template v-slot:body>
                    <div class="row">
                      <div class="col-sm-3">
                        <flat-pickr :config="dateConfig" class="form-control" value="" @on-change="setAvailabilityForDate"/>
                      </div>
                      <div class="col-sm-9 m-0 text-center">
                        <b-button variant="primary" class="mr-2" v-if="previousWeek !== 'false'" @click="setWeek(previousWeek)">{{ $t('booking.prev_week') }}</b-button>
                        {{currentWeek}}
                        <b-button variant="primary" class="mr-2" @click="setWeek(nextWeek)">{{ $t('booking.next_week') }}</b-button>
                      </div>
                    </div>
                  </template>
                </iq-card>
              </b-form-group>
              <b-form-group class="col-sm-12">
                <iq-card>
                  <template v-slot:body>
                    <div class="row">
                      <div class="col-12"  v-if="theDates.length === 0">
                        <div>{{ $t('booking.week_reserved') }} {{ $t('booking.check') }} <b-button variant="primary" class="mr-2" @click="setWeek(nextWeek)">{{ $t('booking.next_week') }}</b-button></div>
                      </div>
                      <div class="col m-0 pr-1 p-0 fontSize" v-for="value in theDates" :key="value.typeOfDay">
                        <div class="text-center" style="font-weight: bold; background: rgb(86 159 247);color: #fff">
                          {{ $t('booking.' + value.typeOfDay) }} <br>
                          {{ value.theDate }}
                        </div>
                        <div v-for="dat in value.values" :key="dat.sDate" @click="setDate(value, dat)" class="dateButton fontSize1" >
                          {{ dat.start }}
                        </div>
                      </div>
                    </div>
                  </template>
                </iq-card>
              </b-form-group>
            </b-row>
          </tab-content-item>
          <tab-content-item :active="false" id="detailsAndPayment" >
            <iq-card>
              <template v-slot:body>
                <b-row>
                  <b-form-group class="col-sm-9">
                    <b-row align-v="center" v-if="!thecompanytoken">
                      <b-form-group class="col-md-6" :label="$t('employee.first_name')" label-for="fname">
                        <ValidationProvider name="First name" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.fname" type="text" :placeholder="$t('employee.first_name')" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group class="col-md-6" :label="$t('employee.last_name')" label-for="lname">
                        <ValidationProvider name="Last name" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.lname" type="text" :placeholder="$t('employee.last_name')" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group class="col-sm-6">
                        <label for="contact_no">{{ $t('employee.contact_no') }}:</label>
                        <b-form-input id="contact_no" type="text" v-model="user.phone"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-sm-6">
                        <label for="email">{{ $t('employee.email') }}:</label>
                        <b-form-input id="email" type="text" v-model="user.email"></b-form-input>
                      </b-form-group>
                      <b-form-input type="text" v-model="user.picture" style="display:none"/>

                      <b-form-group class="col-sm-12 text-center" v-show="socialLogin && !thecompanytoken">
                        {{ $t('booking.orlogin') }} <br><br>
                        <i class="fab fa-facebook fa-4x" >
                          <facebook-login class="button FacebookButton"
                                          appId="212584997302645"
                                          @login="loginFacebook"
                                          @logout="logoutFacebook"
                                          @sdk-loaded="sdkLoaded">
                          </facebook-login>
                        </i>
                        &nbsp;&nbsp;
                        <i class="fab fa-google fa-4x" >
                          <GoogleLogin class="GoogleButton" :params="params" :onSuccess="onSuccess" :onFailure="onFailure">{{ $t('sidebar.login') }}</GoogleLogin>
                        </i>
                      </b-form-group>
                      <b-form-group class="col-sm-12">
                        <b>{{ $t('feature.observations') }}:</b>
                        <b-form-textarea class="description" :placeholder="$t('feature.observations')" v-model="descriptionEvent"></b-form-textarea>
                        <br>
                      </b-form-group>
                      <b-form-group class="col-sm-12"><br>
                        <b-form-checkbox  v-model="user.on_hold"> {{ $t('booking.empty_slot') }}</b-form-checkbox>
                      </b-form-group>
                      <br><br>
                      <b-form-group class="col-sm-12"><br>
                        <b-form-checkbox  v-model="user.notification_sms"> {{ $t('booking.sms_not') }}</b-form-checkbox>
                      </b-form-group>
                      <b-form-group class="col-sm-12">
                        <b-form-checkbox  v-model="user.notification_email"> {{ $t('booking.email_not') }}</b-form-checkbox>
                      </b-form-group>
                      <b-form-group class="col-sm-12">
                        <b-form-checkbox  v-model="user.newsletter"> {{ $t('booking.subscribe')}}</b-form-checkbox>
                      </b-form-group>

                      <b-form-group class="col-sm-12 m-0 prices">
                        {{ $t('extra.subtotal') }}: {{ selectedServicePrice }} {{ currency }}
                      </b-form-group>
                      <b-form-group class="col-sm-12 m-0 prices">
                        {{ $t('extra.discount') }}: {{ selectedServicePriceDiscount }} {{ currency }}
                        <span v-show="!isDiscount && !isDiscountOpened" class="discount" @click="openGiftcard">{{ $t('extra.add_giftcard') }}</span>
                        <span v-show="isDiscountOpened" style="display: block">
                          <input type="text" :placeholder="$t('extra.giftcard_code')" class="isDiscount" v-model="discountText" />
                          <b-button @click="addTheGiftcard" class="btn-sm btn-success" style="font-size:12px">{{ $t('extra.add_giftcard') }}</b-button>
                        </span>
                        <span v-show="isDiscount" class="discountValue">
                          <span>{{ discountValoare }}</span>
                          <span class="underline" @click="removeGiftcard">{{ $t('extra.remove_discount') }}</span>
                        </span>
                        <span v-if="discountError" class="discountError">{{ discountError }}</span>
                      </b-form-group>
                      <b-form-group class="col-sm-12 m-0 prices">
                        <span style="border-top: 1px solid blue; padding-top:8px;">{{ $t('booking.total_to_pay') }}: {{ selectedServicePriceTotal }} {{ currency }}</span>
                      </b-form-group>
                      <b-form-group class="col-sm-12">
                        <label>{{ $t('booking.make_payment') }}:</label><br>
                        <b-form-radio class="text-uppercase" inline v-model="user.payment" v-for="(val, id) in availablePayments" :value="val" :key="id">{{ val }}</b-form-radio>
                      </b-form-group>

                      <b-form-group class="col-sm-12" v-show="user.payment === 'cash'">
                        <b-button type="submit" variant="primary" class="col-sm-12" @click="makeBooking(user)">{{ $t('booking.pay_cash') }}</b-button>
                      </b-form-group>
                      <b-form-group class="col-sm-12" v-show="user.payment === 'paypal'" id="paypalPayment">
                      </b-form-group>
                      <b-form-group class="col-sm-12" v-if="user.payment === 'stripe'" id="stripePayment">
                        <stripe-element-card
                          ref="elementRef1"
                          :pk="stripeUserId"
                          @token="tokenCreated"
                        />
                        <b-button type="submit" variant="primary" class="col-sm-12" @click="payWithStripe" v-if="!paymentInProgress">{{ $t('booking.pay_stripe') }}</b-button>
                      </b-form-group>
                      <b-form-group class="col-sm-12" v-if="user.payment === 'revolut'" id="revolutPayment">
                        <b-button type="submit" variant="primary" class="col-sm-12" @click="createRevolutApiOrder">{{ $t('booking.pay_revolut') }}</b-button>
                      </b-form-group>
                      <div class="col-sm-12 pt-0" v-if="paymentError" style="color: red; font-size: 13px;">{{ paymentError }}</div>
                    </b-row>
                    <div class="col-sm-12 row" v-if="thecompanytoken && device !== 'ios'">
                      <b-form-group class="col-sm-12">
                        <b>{{ $t('feature.observations') }}:</b>
                        <b-form-textarea class="description" :placeholder="$t('feature.observations')" v-model="descriptionEvent"></b-form-textarea>
                        <br>
                      </b-form-group>
                      <b-form-group class="col-sm-12"><br>
                        <b-form-checkbox  v-model="user.on_hold"> {{ $t('booking.empty_slot') }}</b-form-checkbox>
                      </b-form-group>
                      <br><br>
                      <b-form-group class="col-sm-12"><br>
                        <b-form-checkbox  v-model="user.notification_sms"> {{ $t('booking.sms_not') }}</b-form-checkbox>
                      </b-form-group>
                      <b-form-group class="col-sm-12">
                        <b-form-checkbox  v-model="user.notification_email"> {{ $t('booking.email_not') }}</b-form-checkbox>
                      </b-form-group>
                      <b-form-group class="col-sm-12 m-0 prices">
                        {{ $t('extra.subtotal') }}: {{ selectedServicePrice }} {{ currency }}
                      </b-form-group>
                      <b-form-group class="col-sm-12 m-0 prices">
                        {{ $t('extra.discount') }}: {{ selectedServicePriceDiscount }} {{ currency }}
                        <span v-show="!isDiscount && !isDiscountOpened" class="discount" @click="openGiftcard">{{ $t('extra.add_giftcard') }}</span>
                        <span v-show="isDiscountOpened" style="display: block">
                          <input type="text" :placeholder="$t('extra.giftcard_code')" class="isDiscount" v-model="discountText" />
                          <b-button @click="addTheGiftcard" class="btn-sm btn-success" style="font-size:12px">{{ $t('extra.add_giftcard') }}</b-button>
                        </span>
                        <span v-show="isDiscount" class="discountValue">
                          <span>{{ discountValoare }}</span>
                          <span class="underline" @click="removeGiftcard">{{ $t('extra.remove_discount') }}</span>
                        </span>
                        <span v-if="discountError" class="discountError">{{ discountError }}</span>
                      </b-form-group>
                      <b-form-group class="col-sm-12 m-0 prices">
                        <span style="border-top: 1px solid blue; padding-top:8px;">{{ $t('booking.total_to_pay') }}: {{ selectedServicePriceTotal }} {{ currency }}</span>
                      </b-form-group>
                      <b-form-group class="col-sm-12">
                        <label>{{ $t('booking.make_payment') }}:</label><br>
                        <b-form-radio class="text-uppercase" inline v-model="user.payment" v-for="(val, id) in availablePayments" :value="val" :key="id">{{ val === 'stripe' ? 'card' : val }}</b-form-radio>
                      </b-form-group>
                      <b-form-group class="col-sm-12" v-show="user.payment === 'cash'">
                        <b-button type="submit" variant="primary" class="col-sm-12" @click="makeBookingFromApp(user)">{{ $t('booking.pay_cash') }}</b-button>
                      </b-form-group>
                      <b-form-group class="col-sm-12" v-show="user.payment === 'paypal'" id="paypalPayment">
                      </b-form-group>
                      <b-form-group class="col-sm-12" v-if="user.payment === 'stripe'" id="stripePayment">
                        <stripe-element-card
                          ref="elementRef2"
                          :pk="stripeUserId"
                          @token="tokenCreated2"
                        />
                        <b-button type="submit" variant="primary" class="col-sm-12" @click="payWithStripeFromApp" v-if="!paymentInProgress">{{ $t('booking.pay_stripe') }}</b-button>
                      </b-form-group>
                      <b-form-group class="col-sm-12" v-if="user.payment === 'revolut'" id="revolutPayment">
                        <b-button type="submit" variant="primary" class="col-sm-12" @click="createRevolutApiOrderFromApp">{{ $t('booking.pay_revolut') }}</b-button>
                      </b-form-group>
                      <div class="col-sm-12 pt-0" v-if="paymentError" style="color: red; font-size: 13px;">{{ paymentError }}</div>
                    </div>
                    <div class="col-sm-12 row" v-if="thecompanytoken && device === 'ios'">
                      <b-form-group class="col-sm-12">
                        <b-button type="submit" variant="primary" class="col-sm-12" @click="payWithIOS(user)" v-if="!paymentInProgress">{{ $t('booking.make_payment') }}</b-button>
                      </b-form-group>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-sm-3 text-center align-self-center"></b-form-group>
                </b-row>
              </template>
            </iq-card>
          </tab-content-item>
        </tab-content>
      </div>
    </b-col>
    <div class="booking_complete" v-if="bookingComplete">
      <div>{{ $t('booking.booking_successfull') }} <br><br>{{ $t('booking.make_a_new_one') }}<br>
        <b-button variant="success" class="mb-3 mr-1" @click="newBooking()"><i class="ri-bill-fill"></i>{{ $t('booking.new_booking') }}</b-button>
      </div>
    </div>

    <div class="booking_available" v-if="!isAvailable">
      <div>
        {{ $t('pay.booking_not_available') }}
      </div>
    </div>
  </b-row>
</template>
<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import facebookLogin from 'facebook-login-vuejs'
import GoogleLogin from 'vue-google-login'
import { core } from '@/config/pluginInit'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'

export default {
  name: 'CalendarBook',
  components: {
    facebookLogin,
    GoogleLogin,
    StripeElementCard
  },
  props: {
    thecompanytoken: String,
    theuser: Object
  },
  data () {
    return {
      device: Capacitor.getPlatform() || 'web',
      paymentError: false,
      paymentInProgress: false,
      discountValoare: '',
      discountText: '',
      discountError: false,
      isDiscount: false,
      isDiscountOpened: false,
      isAvailable: true,
      isLoadingEvent: false,
      stripeUserId: '',
      token: null,
      bookingComplete: false,
      currency: '',
      paypalPayment: '',
      availablePayments: [],
      errorPersists: false,
      isConnected: false,
      name: '',
      email: '',
      personalID: '',
      picture: '',
      FB: undefined,
      socialLogin: true,
      apiToken: localStorage.getItem('api_token') || '',
      customerApiToken: localStorage.getItem('customer_api_token') || '',
      params: {
        client_id: '469947475180-13204kpb0ljgsbmavfeo4doufpeq8ur9.apps.googleusercontent.com'
      },
      user: {
        fname: '',
        lname: '',
        phone: '',
        email: '',
        payment: '',
        picture: '',
        notification_sms: '',
        notification_email: '',
        newsletter: '',
        on_hold: ''
      },
      companyToken: this.$route.params.id || '',
      employeeToken: this.$route.params.employee || '',
      services: [],
      employees: [],
      availability: [],
      theDates: {
        typeOfDay: '',
        theDate: '',
        values: {}
      },
      nextWeek: true,
      previousWeek: false,
      currentWeek: '',
      search: '',
      searche: '',
      selectedService: [],
      selectedServiceId: [],
      selectedServiceName: '',
      selectedServicePrice: 0,
      selectedServicePriceDiscount: 0,
      selectedServicePriceTotal: 0,
      selectedServiceChecked: [],
      selectedEmployeeId: '',
      selectedEmployeeName: '',
      selectedDate: '',
      selectedDateName: '',
      selectedDay: '',
      selectedDayComplete: '',
      selectedTimeStart: '',
      selectedTimeEnd: '',
      dateConfig: {
        inline: false,
        minDate: 'today',
        dateFormat: 'Y-m-d',
        defaultDate: 'today',
        locale: {
          firstDayOfWeek: 1
        }
      },
      revolutUserId: '',
      revolutInstance: '',
      descriptionEvent: '',
      selectedEmployeeByDefault: []
    }
  },
  methods: {
    payWithIOS (client) {
      // save payment
      this.user.notification_sms = 1
      this.user.notification_email = 1
      this.user.on_hold = 0
      const userName = this.user.name.split(' ')
      this.user.fname = userName[0]
      this.user.lname = userName[1]
      const dataPost = {
        company: this.companyToken,
        employee: this.selectedEmployeeId,
        service: this.selectedServiceId,
        selectedDay: this.selectedDay,
        selectedTimeStart: this.selectedTimeStart,
        selectedTimeEnd: this.selectedTimeEnd,
        client: client,
        currency: this.currency,
        amount: this.selectedServicePriceTotal,
        subtotal: this.selectedServicePrice,
        discount: this.selectedServicePriceDiscount,
        voucher: this.discountValoare,
        transaction: '',
        description: this.descriptionEvent
      }
      Axios.post(API.API_ADD_BOOKING_IOS, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            const bookingToken = response.data.token
            // redirect to payment page
            const openCapacitorSite = async () => {
              await Browser.open({ url: 'https://app.12booky.com/app/payBooking/' + bookingToken })
            }
            console.log(openCapacitorSite())
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    },
    openGiftcard () {
      this.discountText = ''
      this.isDiscountOpened = true
    },
    addTheGiftcard () {
      if (this.discountText === '') {
        this.discountError = this.$t('extra.giftcard_empty_value')
        return
      }
      const dataPost = {
        token: this.discountText,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_ADD_COMPANY_VOUCHER_APPLY, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            const currentVoucher = response.data.voucher
            let voucherPrice = currentVoucher.value
            if (currentVoucher.value_type === '%') {
              voucherPrice = (currentVoucher.value / 100 * parseInt(this.selectedServicePrice)).toFixed(2)
            }
            this.selectedServicePriceDiscount = voucherPrice

            this.selectedServicePriceTotal = this.selectedServicePrice - this.selectedServicePriceDiscount

            const types = currentVoucher.value_type === '%' ? '%' : this.currency
            this.discountValoare = currentVoucher.token + '(' + currentVoucher.value + ' ' + types + ')'
            this.discountError = false
            this.isDiscountOpened = false
            this.isDiscount = true
          } else if (response.data.status === 'required') {
            this.discountError = this.$t('extra.giftcard_empty_value')
          } else {
            this.discountError = this.$t('extra.giftcard_invalid')
          }
        })
        .catch((error) => {
          console.warn(error)
        })
    },
    removeGiftcard () {
      this.selectedServicePriceDiscount = 0
      this.selectedServicePriceTotal = this.selectedServicePrice
      this.discountError = false
      this.isDiscountOpened = false
      this.isDiscount = false
    },
    setAvailabilityForDate (selectedDates, dateStr, instance) {
      const date1 = new Date(dateStr)
      const oneJan = new Date(date1.getFullYear(), 0, 1)
      const numberOfDays = Math.floor((date1 - oneJan) / (24 * 60 * 60 * 1000))
      const theWeek = Math.ceil((date1.getDay() + 1 + numberOfDays) / 7)
      this.getAvailability(theWeek)
    },
    tokenCreated (token) {
      if (token.id) {
        this.sendPaymentToStripe(token)
      }
    },
    tokenCreated2 (token) {
      if (token.id) {
        this.sendPaymentToStripeFromApp(token)
      }
    },
    payWithStripe () {
      if (this.validateSubmission()) {
        this.$refs.elementRef1.submit()
      }
    },
    payWithStripeFromApp () {
      this.$refs.elementRef2.submit()
    },
    sendPaymentToStripe (token) {
      this.paymentError = false
      this.paymentInProgress = true
      const dataPost = {
        token: token,
        currency: this.currency,
        amount: this.selectedServicePriceTotal,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_STRIPE_ORDER, dataPost)
        .then((response) => {
          this.paymentInProgress = false
          if (response.data.status === 'success') {
            this.makeBooking(this.user, response.data.response)
          }
        })
        .catch((error) => {
          console.log(error.response.status)
          this.paymentInProgress = false
          if (error.response && error.response.status === 500) {
            this.paymentError = error.response.data.message
          } else if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    sendPaymentToStripeFromApp (token) {
      this.paymentError = false
      this.paymentInProgress = true
      const dataPost = {
        token: token,
        currency: this.currency,
        amount: this.selectedServicePriceTotal,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_STRIPE_ORDER, dataPost)
        .then((response) => {
          this.paymentInProgress = false
          if (response.data.status === 'success') {
            this.makeBookingFromApp(this.user, response.data.response)
          }
        })
        .catch((error) => {
          this.paymentInProgress = false
          console.log(error.response.status)
          if (error.response && error.response.status === 500) {
            this.paymentError = error.response.data.message
          } else if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    newBooking () {
      this.$router.go()
    },
    initializeRevolut () {
      const script1 = document.createElement('script')
      script1.text = '!function(e,o,t){e[t]=function(n,r){var c={sandbox:"https://sandbox-merchant.revolut.com/embed.js",prod:"https://merchant.revolut.com/embed.js",dev:"https://merchant.revolut.codes/embed.js"},d=o.createElement("script");d.id="revolut-checkout",d.src=c[r]||c.prod,d.async=!0,o.head.appendChild(d);var s={then:function(r,c){d.onload=function(){r(e[t](n))},d.onerror=function(){o.head.removeChild(d),c&&c(new Error(t+" is failed to load"))}}};return"function"==typeof Promise?Promise.resolve(s):s}}(window,document,"RevolutCheckout");'
      document.head.appendChild(script1)
    },
    createRevolutApiOrder () {
      if (this.validateSubmission()) {
        const dataPost = {
          amount: this.selectedServicePriceTotal * 100,
          currency: this.currency,
          userId: this.revolutUserId,
          instanceType: this.revolutInstance
        }

        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        Axios.post(API.API_SET_REVOLUT_ORDER, dataPost)
          .then((response) => {
            if (response.data.status === 'success') {
              if (response.data.response.public_id) {
                this.setRevolutLoaded(response.data.response.public_id, this.revolutInstance, response.data.response)
              } else {
                core.showSnackbar('error', this.$t('booking.check_api_key'))
              }
            }
          })
          .catch((error) => {
            core.showSnackbar('error', error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    createRevolutApiOrderFromApp () {
      const dataPost = {
        amount: this.selectedServicePriceTotal * 100,
        currency: this.currency,
        userId: this.revolutUserId,
        instanceType: this.revolutInstance
      }

      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_REVOLUT_ORDER, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.response.public_id) {
              this.setRevolutLoadedFromApp(response.data.response.public_id, this.revolutInstance, response.data.response)
            } else {
              core.showSnackbar('error', this.$t('booking.check_api_key'))
            }
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setRevolutLoaded (apiKey, instanceType, theResponse) {
      window.RevolutCheckout(apiKey, instanceType).then(function (instance) {
        instance.payWithPopup({
          locale: 'en',
          onSuccess () {
            this.makeBooking(this.user, theResponse)
          },
          onError (message) {
            core.showSnackbar('error', message)
          }
        })
      })
    },
    setRevolutLoadedFromApp (apiKey, instanceType, theResponse) {
      window.RevolutCheckout(apiKey, instanceType).then(function (instance) {
        instance.payWithPopup({
          locale: 'en',
          onSuccess () {
            this.makeBookingFromApp(this.user, theResponse)
          },
          onError (message) {
            core.showSnackbar('error', message)
          }
        })
      })
    },
    initializePaypal (userId) {
      const script = document.createElement('script')
      script.src = 'https://www.paypal.com/sdk/js?client-id=' + userId + '&currency=' + this.currency
      script.addEventListener('load', this.setPaypalLoaded)
      document.head.appendChild(script)
    },
    setPaypalLoaded () {
      const paymentDescription = this.selectedServiceName + ' - ' + this.selectedDateName
      const totalToPay = this.selectedServicePriceTotal
      const clientInfo = this.user
      const currentInstance = this
      window.paypal.Buttons({
        onClick: function (data, actions) {
          if (!currentInstance.validateSubmission()) {
            return actions.reject()
          }
        },
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                description: paymentDescription,
                amount: {
                  value: totalToPay
                }
              }
            ]
          })
        },
        onApprove: function (data, actions) {
          return actions.order.capture().then(function (details) {
            if (details.status === 'COMPLETED') {
              // submit the forms with the rest of the infos
              if (currentInstance.thecompanytoken) {
                currentInstance.makeBookingFromApp(clientInfo, details)
              } else {
                currentInstance.makeBooking(clientInfo, details)
              }
            } else {
              core.showSnackbar('error', this.$t('booking.error_payment'))
            }
          })
        },
        onError: (error) => {
          core.showSnackbar('error', error)
        }
      }).render('#paypalPayment')
    },
    getUserData () {
      this.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
        user => {
          this.personalID = user.id
          this.user.email = user.email
          const theUserName = user.name.split(' ')
          this.user.fname = theUserName[0]
          this.user.lname = theUserName[1]
          this.user.picture = user.picture.data.url
          this.socialLogin = false
        }
      )
    },
    sdkLoaded (payload) {
      this.isConnected = payload.isConnected
      this.FB = payload.FB
      if (this.isConnected) this.getUserData()
    },
    loginFacebook () {
      this.isConnected = true
      this.getUserData()
    },
    logoutFacebook () {
      this.isConnected = false
    },
    selectEmployee () {
      this.isLoadingEvent = true
      if (this.selectedService.length > 0) {
        if (this.selectedEmployeeByDefault && this.selectedEmployeeByDefault.length > 0) {
          this.selectedEmployeeId = this.selectedEmployeeByDefault[0].token
          console.log(this.selectedEmployeeByDefault)
          this.setEmployee(this.selectedEmployeeByDefault[0])
        } else {
          this.getEmployees()
          this.$refs.theEmployees.$el.children[0].click()
        }
      } else {
        this.isLoadingEvent = false
        core.showSnackbar('error', this.$t('booking.select_service_first'))
      }
    },
    setService (item) {
      if (this.$refs['service_' + item.id][0].$el.classList.contains('isSelected')) {
        const arrayIndex = this.selectedService.indexOf(item)
        this.selectedService.splice(arrayIndex, 1)
        this.$refs['service_' + item.id][0].$el.classList.remove('isSelected')
      } else {
        this.selectedService.push(item)
        this.$refs['service_' + item.id][0].$el.classList.add('isSelected')
      }

      if (this.selectedService.length > 0) {
        this.selectedServiceName = ''
        this.selectedServiceId = []
        this.selectedServicePrice = 0
        for (let x = 0; x < this.selectedService.length; x++) {
          this.selectedServiceId.push(this.selectedService[x].token)
          const addComma = (x > 0) ? ', ' : ''
          this.selectedServiceName += addComma + this.selectedService[x].name
          this.selectedServicePrice = this.selectedServicePrice + parseFloat(this.selectedService[x].price)
        }
      } else {
        this.selectedServiceId = []
        this.selectedServiceName = ''
        this.selectedServicePrice = 0
      }

      this.selectedServicePriceTotal = this.selectedServicePrice
      this.selectedServicePriceDiscount = 0
      this.discountError = false
      this.isDiscountOpened = false
      this.isDiscount = false

      console.log(this.selectedServiceId)
    },
    setEmployeeWithAvailability () {
      const dataPost = {
        company: this.companyToken,
        service: this.selectedServiceId
      }
      Axios.post(API.API_GET_FIRST_AVAILABLE_EMPLOYEE, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            const theItem = {
              token: response.data.theEmployee.employee.token,
              name: response.data.theEmployee.employee.fname + ' ' + response.data.theEmployee.employee.lname
            }
            this.setEmployee(theItem)
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setEmployee (item) {
      this.selectedEmployeeId = item.token
      this.selectedEmployeeName = item.name
      this.getAvailability()
      this.$refs.theProgram.$el.children[0].click()
    },
    setDate (item1, item) {
      this.selectedDate = item.time
      this.selectedDateName = item1.typeOfDay + ', ' + item1.theDate + ' Time: ' + item.start + '-' + item.end
      this.selectedDay = item1.theDateSend
      this.selectedTimeStart = item.start
      this.selectedTimeEnd = item.end
      this.$refs.theDetails.$el.children[0].click()
    },
    filteredListEmployee () {
      const searchValue1 = this.searche.toLowerCase()
      if (searchValue1.length > 0) {
        for (let i = 0; i < this.employees.length; i++) {
          if (this.employees[i].name.toLowerCase().includes(searchValue1)) {
            this.$refs['employee_' + this.employees[i].id][0].$el.classList.remove('d-none')
          } else {
            this.$refs['employee_' + this.employees[i].id][0].$el.classList.add('d-none')
          }
        }
      } else {
        for (let i = 0; i < this.employees.length; i++) {
          this.$refs['employee_' + this.employees[i].id][0].$el.classList.remove('d-none')
        }
      }
    },
    filteredList () {
      const searchValue = this.search.toLowerCase()
      const theElement = document.getElementsByClassName('suppress')
      console.log(theElement)
      if (searchValue.length > 0) {
        for (let i = 0; i < theElement.length; i++) {
          theElement[i].classList.add('isSearched')
        }
        for (let i = 0; i < this.services.length; i++) {
          for (let j = 0; j < this.services[i].list.length; j++) {
            if (this.services[i].list[j].name.toLowerCase().includes(searchValue)) {
              this.$refs['service_' + this.services[i].list[j].id][0].$el.classList.remove('d-none')
            } else {
              this.$refs['service_' + this.services[i].list[j].id][0].$el.classList.add('d-none')
            }
          }
        }
      } else {
        for (let i = 0; i < theElement.length; i++) {
          theElement[i].classList.remove('isSearched')
        }
        for (let i = 0; i < this.services.length; i++) {
          for (let j = 0; j < this.services[i].list.length; j++) {
            this.$refs['service_' + this.services[i].list[j].id][0].$el.classList.remove('d-none')
          }
        }
      }
    },
    checkToken () {
      const token = {
        token: this.companyToken
      }
      Axios.post(API.API_CHECK_COMPANY_TOKEN, token)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.theEmployee) {
              this.selectedEmployeeByDefault = response.data.theEmployee
            }
          } else if (response.data.status === 'fail') {
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    checkEmployeeToken () {
      const token = {
        company: this.$route.params.id,
        employee: this.employeeToken
      }
      Axios.post(API.API_CHECK_EMPLOYEE_TOKEN, token)
        .then((response) => {
          if (response.data.status === 'success') {
            this.selectedEmployeeByDefault = response.data.theEmployee
          } else if (response.data.status === 'fail') {
            this.$router.push('/pages/error/404')
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getServices () {
      const dataPost = {
        company: this.companyToken,
        employee: this.employeeToken
      }
      Axios.post(API.API_SERVICES, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.services = response.data.services
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getEmployees () {
      const dataPost = {
        service: this.selectedServiceId,
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_EMPLOYEES_BY_SERVICE_NO_AUTH, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.employees = response.data.employees
            this.isLoadingEvent = false
          }
        })
        .catch((error) => {
          this.isLoadingEvent = false
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setWeek (week) {
      this.getAvailability(week)
    },
    getCompanyLanguage () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.post(API.API_GET_COMPANY_LANGUAGE, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.$i18n.locale = response.data.data.language
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    hasBookings () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.post(API.API_HAS_BOOKINGS_TODAY, dataPost)
        .then((response) => {
          if (response.data.status === 'upgrade') {
            this.isAvailable = false
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    },
    getCompanyCurrency () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.post(API.API_GET_AVAILABLE_CURRENCY, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.currency = response.data.data.currency
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getAvailability (week) {
      this.isLoadingEvent = true

      const dataPost = {
        company: this.companyToken,
        employee: this.selectedEmployeeId || 'firstAvailableEmployee',
        service: this.selectedServiceId,
        week: week
      }
      Axios.post(API.API_GET_AVAILABILITY_BY_EMPLOYEE, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.availability = response.data.availability
            this.previousWeek = this.availability.previousWeek
            this.nextWeek = this.availability.nextWeek
            this.currentWeek = this.availability.currentWeek
            this.theDates = this.availability.theDates
            this.getAvailablePayments()
            this.isLoadingEvent = false
          }
        })
        .catch((error) => {
          this.isLoadingEvent = false
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getAvailablePayments () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.post(API.API_GET_AVAILABLE_PAYMENTS, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.data.available_payments) {
              this.availablePayments = response.data.data.available_payments
            }
            if (response.data.data.payment_test) {
              this.testPayment = response.data.data.payment_test
            }
            if (response.data.data.payment_paypal) {
              const paymentPaypal = JSON.parse(response.data.data.payment_paypal)
              if (paymentPaypal.active === true) {
                const paypalUserId = (this.testPayment === '1') ? paymentPaypal.address_test : paymentPaypal.address
                this.initializePaypal(paypalUserId)
              }
            }
            if (response.data.data.payment_revolut) {
              const paymentRevolut = JSON.parse(response.data.data.payment_revolut)
              if (paymentRevolut.active === true) {
                this.revolutUserId = (this.testPayment === '1') ? paymentRevolut.api_sandbox : paymentRevolut.api
                this.revolutInstance = (this.testPayment === '1') ? 'sandbox' : 'prod'
                this.initializeRevolut()
              }
            }
            if (response.data.data.payment_stripe) {
              const paymentStripe = JSON.parse(response.data.data.payment_stripe)
              if (paymentStripe.active === true) {
                this.stripeUserId = (this.testPayment === '1') ? paymentStripe.test_publishable : paymentStripe.live_publishable
              }
            }
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    makeBooking (client, transaction = null) {
      if (this.validateSubmission()) {
        this.isLoadingEvent = true
        const dataPost = {
          company: this.companyToken,
          employee: this.selectedEmployeeId,
          service: this.selectedServiceId,
          selectedDay: this.selectedDay,
          selectedTimeStart: this.selectedTimeStart,
          selectedTimeEnd: this.selectedTimeEnd,
          client: client,
          currency: this.currency,
          amount: this.selectedServicePriceTotal,
          subtotal: this.selectedServicePrice,
          discount: this.selectedServicePriceDiscount,
          voucher: this.discountValoare,
          transaction: transaction,
          description: this.descriptionEvent
        }
        Axios.post(API.API_ADD_BOOKING, dataPost)
          .then((response) => {
            if (response.data.status === 'success') {
              this.isLoadingEvent = false
              this.bookingComplete = true
              this.paymentInProgress = false
            }
          })
          .catch((error) => {
            core.showSnackbar('error', error.response)
          })
      }
    },
    makeBookingFromApp (client, transaction = null) {
      this.user.notification_sms = 1
      this.user.notification_email = 1
      this.user.on_hold = 0
      const userName = this.user.name.split(' ')
      this.user.fname = userName[0]
      this.user.lname = userName[1]
      const dataPost = {
        company: this.companyToken,
        employee: this.selectedEmployeeId,
        service: this.selectedServiceId,
        selectedDay: this.selectedDay,
        selectedTimeStart: this.selectedTimeStart,
        selectedTimeEnd: this.selectedTimeEnd,
        client: client,
        currency: this.currency,
        amount: this.selectedServicePriceTotal,
        subtotal: this.selectedServicePrice,
        discount: this.selectedServicePriceDiscount,
        voucher: this.discountValoare,
        transaction: transaction,
        description: this.descriptionEvent
      }
      Axios.post(API.API_ADD_BOOKING, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('booking.booking_successfull'))
            setTimeout(() => {
              location.reload()
            }, 2000)
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    },
    validateSubmission () {
      this.errorPersists = false
      if (!this.companyToken) {
        this.errorPersists = this.$t('booking.missing_token')
      } else if (!this.selectedServiceId) {
        this.errorPersists = this.$t('booking.missing_service')
      } else if (!this.selectedEmployeeId) {
        this.errorPersists = this.$t('booking.missing_employee')
      } else if (!this.selectedDay) {
        this.errorPersists = this.$t('booking.missing_day')
      } else if (!this.selectedTimeStart) {
        this.errorPersists = this.$t('booking.missing_time')
      } else if (!this.user.fname) {
        this.errorPersists = this.$t('booking.missing_fname')
      } else if (!this.user.lname) {
        this.errorPersists = this.$t('booking.missing_lname')
      } else if (!this.user.phone) {
        this.errorPersists = this.$t('booking.missing_phone')
      } else if (!this.user.email) {
        this.errorPersists = this.$t('booking.missing_email')
      } else if (!this.user.payment) {
        this.errorPersists = this.$t('booking.missing_payments')
      }

      if (this.errorPersists) {
        core.showSnackbar('error', this.errorPersists)
        return false
      }

      return true
    },
    onSuccess (googleUser) {
      const googleProfile = googleUser.getBasicProfile()
      this.user.email = googleProfile.Et
      this.user.fname = googleProfile.ST
      this.user.lname = googleProfile.UR
      this.user.picture = googleProfile.iJ
      this.socialLogin = false
    },
    onFailure (data) {
    },
    toggleCard (id) {
      const theElement = document.getElementById('serv_' + id)
      const plus = document.getElementById('plus' + id)
      const minus = document.getElementById('minus' + id)

      if (theElement.classList.contains('suppress')) {
        theElement.classList.remove('suppress')
        plus.style.display = 'none'
        minus.style.display = 'block'
      } else {
        theElement.classList.add('suppress')
        plus.style.display = 'block'
        minus.style.display = 'none'
      }
    },
    getCurrentUser () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.customerApiToken
      Axios.get(API.API_CUSTOMER_GET_CUSTOMER)
        .then((response) => {
          if (response.data.status === 'success') {
            this.user = JSON.parse(response.data.user)
          }
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  },
  mounted () {
    this.isLoadingEvent = true
    if (this.$props.thecompanytoken) {
      this.companyToken = this.$props.thecompanytoken
      // this.user = this.$props.theuser
      this.getCurrentUser()
    } else {
      this.companyToken = this.$route.params.id
    }
    this.checkToken()
    if (this.employeeToken && this.employeeToken !== '') {
      this.checkEmployeeToken()
    }
    this.getCompanyLanguage()
    this.getCompanyCurrency()
    this.getServices()
    window.addEventListener('load', function () {
      setTimeout(() => {
        const message = { height: document.body.scrollHeight }
        window.top.postMessage(message, '*')
      }, 500)
    })
    window.addEventListener('resize', function () {
      setTimeout(() => {
        const message = { height: document.body.scrollHeight }
        window.top.postMessage(message, '*')
      }, 500)
    })
    window.addEventListener('click', function () {
      setTimeout(() => {
        const message = { height: document.body.scrollHeight }
        window.top.postMessage(message, '*')
      }, 500)
    })
    this.isLoadingEvent = false
    this.hasBookings()
  }
}
</script>
<style>
.fa-facebook,
.fa-google {
  position: relative;
}
.FacebookButton{
  position: absolute;
  width: 50px !important;
  overflow: hidden;
  height: 50px;
  top: 0;
  left: calc( 50% - 25px );
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0;
}
.FacebookButton button{
  height: 50px;
}
.GoogleButton{
  position: absolute;
  width: 50px !important;
  overflow: hidden;
  height: 50px;
  top: 0;
  left: calc( 50% - 25px );
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0;
}
.booking_complete{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000000eb;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999;
}
.booking_complete div{
  position: absolute;
  top: 40%;
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 20px;
}
.image_of_service,
.image_of_employee{
  overflow: hidden;
}
.image_of_service img,
.image_of_employee img{
  height: 120px;
  width: 120px !important;
  object-fit: cover;
}
.isSelected .card{
  background: #ffe173 !important;
}
.dateButton{
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  padding-bottom: 5px;
  background: #e9ecef;
  color: #646464;
  border-radius: 5px;
}
.dateButton:hover{
  background: #0c88b4;
}
.disabled {
  pointer-events:none;
  opacity:0.6;
}
@media only screen and (max-width: 767px) {
  .fontSize{
    font-size: 12px;
  }
  .fontSize1{
    font-size: 14px;
  }

}
.b-card-details{
  font-size: 12px;
  line-height: 13px;
}
.isLoadingEvent{
  background: #000000e6;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #19bdb4;
  width: 120px !important;
  height: 120px !important;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  top: 15% !important;
  bottom: auto !important;
}
.booking_available{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000000eb;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999;
}
.booking_available div{
  position: absolute;
  top: 40%;
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 20px;
}
.topStickyHeader{
  top: 0;
  position: sticky;
  background: white;
  z-index: 999;
}
.suppress{
  display: none !important;
}
.extendible{
  position: absolute;
  right: 10px;
  top: 7px;
}
.fa-minus-square{
  display: none;
}
.suppress.isSearched{
  display: block !important;
}
.discount{
  font-size: 12px;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
}
.discountValue{
  font-size: 12px;
  display: block;
  cursor: pointer;
}
.discountValue span{
  color: #ff9900;
}
.discountValue span.underline{
  text-decoration: underline;
  color: red;
  margin-left: 10px;
}
.isDiscount{
  font-size: 14px !important;
  color: black;
  border: 1px solid grey;
}
.discountError{
  font-size:12px;
  color: red;
}
.prices{
  color: #005aff;
  font-size: 17px;
  font-weight: bold;
}
.description {
  line-height: 20px !important;
}
</style>
